import fetch from '../fetch';
import asyncAppend from "../async-append";
import $ from "jquery";
import {getElementBySelector, getElementsBySelectorObject} from "./selector-utils";

const defaultSelectors = {
    base: '.js-load-more',
    link: '.js-load-more__link',
    result: '.js-load-more__result',
    loading: '.js-load-more__loading',
    notifications: '.js-load-more__notifications'
};

export function createLoadMore($baseElement, selectors = defaultSelectors) {
    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));

    function addLinkClickHandler($links) {
        $links.on('click', function (evt) {
            evt.preventDefault();

            let $baseEl = $(this).closest(selectors.base);
            let $elements = getElementsBySelectorObject(selectors, $baseEl);

            let href = $(this).attr('href') || $(this).data('href');
            let $result = $(this).closest(selectors.result);

            asyncAppend({
                $target: $result,
                $loading: $elements.loading,
                $notifications: $elements.notifications
            }, fetch(href)).then((result) => {
                addLinkClickHandler(getElementBySelector(selectors.link, $baseEl));
            }).catch(() => {
            });
        });
    }
}

export const initInScope = ($scope, selectors = defaultSelectors) => {
    $scope.find(selectors.base).each(function(_,el) {
        createLoadMore($(el),selectors);
    });
}

